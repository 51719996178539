import { defineNuxtRouteMiddleware } from "#app";
import { storeToRefs } from "pinia";
import { notAllowFetchUserGuestList } from "~/config/constant";
import { useAuthStore } from "~/stores/authStore";
import { useWalletStore } from "~/stores/walletStore";

export default defineNuxtRouteMiddleware(async (to: any) => {
  const token = useToken();
  const walletStore = useWalletStore();
  const authStore = useAuthStore();
  const { fetchUser } = authStore;
  const { setListWallet, setListCurrencies } = walletStore;
  const { user } = storeToRefs(authStore);
  const { listCurrencies } = storeToRefs(walletStore);
  const promises: Promise<any>[] = [];

  const shouldFetchUser = (
    { alwaysCall }: { alwaysCall?: boolean } = { alwaysCall: false }
  ) => {
    const slug = to?.params?.slug;

    if (token.value) {
      if (alwaysCall) {
        return true;
      }

      return !user.value?.id;
    }

    const middlewarePaths = notAllowFetchUserGuestList(slug);
    const normalizedPath = to.path.replace(/\/$/, "");
    return !middlewarePaths.includes(normalizedPath);
  };

  if (shouldFetchUser() && import.meta.client) {
    promises.push(fetchUser());
    promises.push(setListWallet());
  }
  if (!listCurrencies.value) {
    promises.push(setListCurrencies());
  }
  await Promise.all(promises);
});
